import "./utils/array.native";
import "./utils/object.native";
import "core-js/features/url";
import axios from "axios";

require("./config");
// require("./sentry");
require("./webview");
require("./utils/ga");

window.axios = axios.create();
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
window.axios.defaults.headers.common["X-Client-Token"] = window.clientConfig.token;
window.axios.defaults.headers.common["X-Project-Key"] = window.appConfig.project.key;

window.appGlobalCache = {};
window.sessionStorage.clear();

require("./app");