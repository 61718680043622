import React from "react";

export default function CreativeLoaderView(props) {
  return <React.Fragment>
    <div className="loader-roller-wrapper">
      <div className="loader-roller loader-roller1">
        <div className="roller" />
        <div className="roller" />
      </div>
      <div className="loader-roller loader-roller2">
        <div className="roller" />
        <div className="roller" />
        </div>
      <div className="loader-roller loader-roller3">
        <div className="roller" />
        <div className="roller" />
      </div>
    </div>
    {props.previewUrl && <div className="loader-bg">
      <img src={props.previewUrl} alt="." />
    </div>}
  </React.Fragment>;
}
