export const supportedLanguagePaths = ["en", "es", "fr", "it", "ja", "ko", "pt", "ru", "zh"];

export default {
  INDEX: "/",
  //INDEX: "/:lang(" + supportedLanguagePaths.join("|") + ")?",
  UPLOAD: "/upload",
  PROCESSING: "/processing/:hash?",
  RESULT: "/result",
  ERROR: "/error",
};
