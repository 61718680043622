import React from "react";
import i18n from "../i18n";
import Slider from "react-slick";
import {hitEvent, hits, logEvent, userEvents} from "../utils/log";
import AppContext from "../contexts/AppContext";
import {goToProcessing} from "../utils/url";
import processingManager from "../photolab/ProcessingManager";
import {assetUrl} from "../utils/etc";
import {APPSTORE_LINK, PLAY_MARKET_LINK} from "../utils/constants";
import Processing from "../photolab/Processing";
import {upperCaseFirstChar} from "../utils/text";
import {webviewAnalyticsEvent} from "../utils/webview";

const suggestions = [
  {term: "Elon Musk"},
  {term: "New York"},
  {term: "Billie Eilish"},
  {term: "October Vibes"},
  {term: "Cristiano Ronaldo"},
  {term: "Johnny Depp VS Amber Heard"},
  {term: "Mark Zuckerberg"},
].shuffle();

const slides = [
  {
    img: "1_Frida_Kahlo.jpg",
    text: "<p>Frida Kahlo</p>",
  },
  {
    img: "2_skeleton_in_yellow_hat.jpg",
    text: "<p>skeleton</p><p class='second-text'>in yellow hat</p>",
  },
  {
    img: "3_New_York.jpg",
    text: "<p>New York</p>",
  },
  {
    img: "4_astronaut_riding_a_horse.jpg",
    text: "<p>astronaut</p><p class='second-text'>riding a horse</p>",
  },
  {
    img: "5_cat_ghost.jpg",
    text: "<p>cat ghost</p>",
  },
  {
    img: "6_witch_portrait.jpg",
    text: "<p>witch</p><p class='second-text'>portrait</p>",
  },
  {
    img: "7_zombie.jpg",
    text: "<p>zombie</p>",
  },
  {
    img: "8_scary.jpg",
    text: "<p>scary</p>",
  },
  {
    img: "9_witch.jpg",
    text: "<p>witch</p>",
  },
  {
    img: "10_Surrealism.jpg",
    text: "<p>Surrealism</p>",
  },
  {
    img: "11_woman.jpg",
    text: "<p>woman</p>",
  },
  {
    img: "12_cat.jpg",
    text: "<p>cat</p>",
  },
  {
    img: "13_astronaut_on_the_moon.jpg",
    text: "<p>astronaut</p><p class='second-text'>on the moon</p>",
  },
  {
    img: "14_ghost.jpg",
    text: "<p>ghost</p>",
  },
];

const view = {
  main: "main",
  search: "search",
}

export default class IndexPage extends React.Component {

  state = {
    term: "",
    termSuggestions: [],
    view: view.main,
    error: null,
    isDropdownOpen: false,
    visibleSlides: [],
  };

  constructor(props) {
    super(props);

    const locationState = this.props.location.state || {};

    this.state.view = locationState.view || view.main;
    this.state.termSuggestions = this.state.view === view.search ? suggestions : [];

    this.termField = null;
  }

  componentDidMount() {
    this.setVisibleSlides();

    if (window.clientConfig.isWebDesktop) {
      hitEvent(hits.INDEX_VISIT_WEB_DESKTOP);
    } else if (window.clientConfig.isWebMobile) {
      hitEvent(hits.INDEX_VISIT_WEB_MOBILE);
    }

    logEvent(userEvents.PAGE, {page: "index"});

    //this.state.view === view.main && document.body.classList.add("--bg-image");

    this.context.hideLoader();
  }

  componentWillUnmount() {
    //document.body.classList.remove("--bg-image");
  }

  handleAppStoreClick = () => {
    hitEvent(hits.GET_APP_CLICK);
    logEvent(userEvents.GET_APP_CLICK, {page: "index"});

    window.open(APPSTORE_LINK, "_blank");
  }

  handlePlayMarketClick = () => {
    hitEvent(hits.GET_APP_CLICK);
    logEvent(userEvents.GET_APP_CLICK, {page: "index"});

    window.open(PLAY_MARKET_LINK, "_blank");
  }

  handleGetAppButtonClick = () => {
    if (window.clientConfig.isWebDesktop) {
      this.setState({
        isDropdownOpen: !this.state.isDropdownOpen
      });

      return;
    }

    setTimeout(() => {
      window.clientConfig.platform.os.toLowerCase() === "ios"
        ? this.handleAppStoreClick()
        : this.handlePlayMarketClick();
    }, 300);
  };

  handleShowMainView = () => {
    logEvent(userEvents.CLOSE_TERM_FORM);

    this.setState({
      view: view.main,
      termSuggestions: [],
    }, this.slider.slickPlay);
  };

  handleTermFieldFocused = () => {
    const termSuggestions = window.clientConfig.isWebDesktop
      ? suggestions
      : suggestions.slice(0, 1);

    this.setState({
      view: view.search,
      termSuggestions: termSuggestions,
    });
  };

  handleTermFieldBlurred = () => {
    setTimeout(() => {
      this.setState({
        termSuggestions: suggestions
      });
    }, 200);
  };

  handleTermFieldChanged = (e) => {
    const value = e.target.value;

    this.setState({
      term: value,
      error: null,
    });
  };

  handleTermSuggestionClicked = (suggestion) => {
    this.setState({
      term: suggestion.term,
      error: null,
    });
  };

  handleTermCancel = () => {
    this.setState({
      term: "",
      error: null,
    });
  };

  handleTermSubmit = (e) => {
    e.preventDefault();

    if (this.state.view === view.main) {
      logEvent(userEvents.OPEN_TERM_FORM);

      this.setState({
        termSuggestions: suggestions,
        view: view.search,
      }, () => {
        this.slider.slickPause();

        if (window.clientConfig.isWebDesktop && this.termField) {
          this.termField.focus();
        }
        //document.body.classList.remove("--bg-image");
      });

      return;
    }

    const term = upperCaseFirstChar(this.state.term.trim());

    if (this.state.view === view.search && term.length === 0) {
      this.setState({
        error: i18n.t("term_error_required"),
      });

      return;
    }

    processingManager.clear();

    webviewAnalyticsEvent("generation_started", term);
    logEvent(userEvents.SUBMIT_TERM_FORM, {
      text: term,
    });

    this.context.showLoader(true, null, "loader-with-upload-form");

    goToProcessing(
      this.props.history,
      Processing.STEP_LAYOUT,
      null,
      null,
      term
    );
  };

  setVisibleSlides = (currentSlide = 0) => {
    const n = slides.length;
    const visibleSlides = [];
    const s = window.clientConfig.isWebDesktop ? 2 : 1;

    for (let i = (currentSlide - s); i <= (currentSlide + s);  i++) {
      visibleSlides.push((i % n + n) % n);
    }

    this.setState({visibleSlides});
  }

  render() {
    const isSearchView = this.state.view === view.search;

    const classNames = ["main-page"];
    isSearchView && classNames.push("term-suggestions-open");

    const classNamesInput = ["form-area"];
    this.state.error && classNamesInput.push("form-area-error");

    var settings = {
      dots: true,
      arrows: false,
      className: "swiper",
      infinite: true,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 1000,
      centerMode: true,
      centerPadding: "220px",
      slidesToShow: 3,
      slidesToScroll: 1,
      beforeChange: (prev, next) => {
        this.setVisibleSlides(next);
      },
      responsive: [
        {
          breakpoint: 3000,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 2000,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            centerPadding: "280px"
          }
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: "360px"
          }
        },
        {
          breakpoint: 1050,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: "380px",
            dots: false,
          }
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: "340px",
            dots: false,
          }
        },
        {
          breakpoint: 850,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: "240px",
            dots: false,
          }
        },
        {
          breakpoint: 730,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: "240px",
            dots: false,
          }
        },
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: "200px",
            dots: false,
          }
        },
        {
          breakpoint: 530,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: "160px",
            dots: false,
          }
        },
        {
          breakpoint: 460,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: "100px",
            dots: false,
          }
        },
        {
          breakpoint: 430,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: "70px",
            dots: false,
          }
        },
        {
          breakpoint: 360,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: "60px",
            dots: false,
          }
        }
      ]
    };

    const appBtnIsHidden = isSearchView
      || window.clientConfig.isWebview
      || (window.clientConfig.isWebMobile && window.clientConfig.platform.os.toLowerCase() !== "ios");

    appBtnIsHidden && classNames.push("app-btn-hidden");

    return <main className={classNames.join(" ")}>
      <section className="header">
        <div className="container">
          <div className="apps-container" hidden={appBtnIsHidden}>
            <button className={"btn-app" + (this.state.isDropdownOpen ? " open" : "")} onClick={this.handleGetAppButtonClick}>get the app</button>
            {window.clientConfig.isWebDesktop && <div className={"app-links" + (this.state.isDropdownOpen ? " open" : "")}>
              <button onClick={this.handleAppStoreClick} children="app store">
                <AppStore />
                <span>The App store</span>
              </button>
              {/*<button onClick={this.handlePlayMarketClick} children="google play">*/}
              {/*  <GooglePlay />*/}
              {/*  <span>Google Play</span>*/}
              {/*</button>*/}
            </div>}
          </div>

          <h1 dangerouslySetInnerHTML={{__html: i18n.t("index_heading")}} />
        </div>
      </section>

      <div className="container">
        {/* <p>{i18n.t("index_text_1")}</p> */}

        <button
          className="btn-close"
          hidden={!isSearchView}
          onClick={this.handleShowMainView}>
          <svg viewBox="0 0 10 10" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M9 0 5 4 1 0 0 1l4 4-4 4 1 1 4-4 4 4 1-1-4-4 4-4-1-1z" fill="#fff"/>
          </svg>
        </button>
      </div>

      <div className="slider">
        <Slider ref={slider => (this.slider = slider)} {...settings}>
          {slides.map((slide, key) =>
            <div className="slide" key={key}>
              <Slide
                initIndex={key}
                visibleIndexes={this.state.visibleSlides}
                thumb={assetUrl(`assets/images/slides/thumb/${slide.img}`)}
                src={assetUrl(`assets/images/slides/${slide.img}`)}
                text={slide.text}
              />
            </div>
          )}
        </Slider>

        <div className="container form-container">
          <div className="notice">
            <p>{i18n.t("index_text_2")}</p>
            <svg viewBox="0 0 23 23" fill="none">
              <circle cx="11.5" cy="11.5" r="11.5" fill="#B7FFCF"/>
              <path d="M12.338 8.3a1.594 1.594 0 1 0 0-3.189 1.594 1.594 0 0 0 0 3.188zM13.968 14.768a.397.397 0 0 0-.558.116 6.616 6.616 0 0 1-1.4 1.502c-.13.1-.62.478-.828.399-.144-.044-.06-.327-.032-.447l.211-.626c.088-.255 1.614-4.782 1.782-5.3.247-.757.14-1.503-.989-1.323-.307.032-3.42.434-3.475.438a.4.4 0 1 0 .052.797s1.195-.155 1.327-.167a.351.351 0 0 1 .339.175.985.985 0 0 1-.028.682c-.104.398-1.742 5.013-1.794 5.28a1.108 1.108 0 0 0 .47 1.196c.363.241.794.358 1.228.33.422-.004.84-.088 1.232-.246.992-.399 2.028-1.459 2.574-2.292a.399.399 0 0 0-.111-.514z" fill="#302C40"/>
            </svg>
          </div>
          <form className="form" onSubmit={this.handleTermSubmit}>
            <div className="form-container" hidden={!isSearchView}>
              <div className="form-input-container">
                <input
                  type="text"
                  ref={(ref) => this.termField = ref}
                  placeholder={i18n.t("term_placeholder")}
                  className={classNamesInput.join(" ")}
                  value={this.state.term}
                  onFocus={this.handleTermFieldFocused}
                  onBlur={this.handleTermFieldBlurred}
                  onChange={this.handleTermFieldChanged} />
                <button
                  className="form-btn-cancel"
                  type="button"
                  hidden={this.state.term.length === 0}
                  onClick={this.handleTermCancel}>
                  <svg viewBox="0 0 10 10">
                    <path fillRule="evenodd" clipRule="evenodd" d="M9 0 5 4 1 0 0 1l4 4-4 4 1 1 4-4 4 4 1-1-4-4 4-4-1-1z" fill="#fff"/>
                  </svg>
                </button>
              </div>
              {this.state.error && <p className="error-message">
                <svg viewBox="0 0 9 9" fill="none">
                  <path d="M4.5 0a4.5 4.5 0 1 0 0 9 4.5 4.5 0 0 0 0-9zm0 8.438A3.942 3.942 0 0 1 .562 4.5 3.942 3.942 0 0 1 4.5.562 3.942 3.942 0 0 1 8.438 4.5 3.942 3.942 0 0 1 4.5 8.438z" fill="#FF7675"/>
                  <path d="m4.901 5.271.154-3.161h-1.11l.16 3.161h.796zM4.503 5.62c-.37 0-.627.265-.627.635 0 .363.25.635.627.635s.622-.272.622-.635c-.008-.37-.252-.635-.622-.635z" fill="#FF7675"/>
                </svg>
                {this.state.error}
              </p>}
            </div>

            <div className="suggestion-container" hidden={!isSearchView}>
              {this.state.termSuggestions.map((suggestion) => <span
                className="suggestion-item"
                key={suggestion.term}
                onClick={() => this.handleTermSuggestionClicked(suggestion)}
                children={suggestion.term}
              />)}
            </div>

            <div className="btns-container btns-container-upload">
              <button className="btn-upload-foto" type="submit">
                {i18n.t(isSearchView ? "index_generate_button_2" : "index_generate_button_1")}
              </button>
            </div>
          </form>
        </div>
      </div>

      <section className="footer" hidden={isSearchView || window.clientConfig.isWebview}>
        <div className="container">
          <a href="https://www.toonlabs.co/privacy-policy"
             target="_blank"
             rel="noopener noreferrer">{i18n.t("privacy_policy")}</a>
          <a href="https://www.toonlabs.co/terms-of-service"
             target="_blank"
             rel="noopener noreferrer">{i18n.t("terms")}</a>
          <a href="mailto:business@toonlabs.co">{i18n.t("contact_us")}</a>
        </div>
      </section>
      <BgBottom />
      <BgTop />
    </main>;
  }
}

IndexPage.contextType = AppContext;

class Slide extends React.Component {
  state = {
    isThumbLoaded: false,
    isFullLoaded: false,
  };

  render() {
    const src = this.props.visibleIndexes.includes(this.props.initIndex) ? this.props.src : null;

    return <React.Fragment>
      {!src && !this.state.isFullLoaded && <img
        className="image thumb"
        src={this.props.thumb}
        onLoad={() => {this.setState({isThumbLoaded: true})}}
        alt="" />}
      {(src || this.state.isFullLoaded) && <img
        className="image full"
        style={{opacity: this.state.isFullLoaded ? 1 : 0}}
        src={this.props.src}
        onLoad={() => {this.setState({isFullLoaded: true})}}
        alt="" />}
      {(this.state.isFullLoaded || this.state.isThumbLoaded) && <div className="slide-toolptip">
        <div className="slide-toolptip-text" dangerouslySetInnerHTML={{__html: this.props.text}} />
        <svg viewBox="0 0 70 48" fill="none">
          <path d="M4.015 22.359C7.965 32.987 23.5 46.87 37.5 37.499c14-9.37 19.5-25 22.782-34.719" stroke="#B7FFCF" strokeWidth="3" strokeLinecap="round"/>
          <path d="m1.5 30.5 2-9 8.5 2" stroke="#B7FFCF" strokeWidth="3" strokeLinecap="round"/>
        </svg>
      </div>}
    </React.Fragment>;
  }
}

function GooglePlay() {
  return <svg width="38" height="43" viewBox="0 0 38 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.81 1.136A3.633 3.633 0 0 0 2.326.969l18.79 18.83 6.143-6.173L5.81 1.136zM.6 2.712a3.815 3.815 0 0 0-.366 1.635V38.79c0 .578.13 1.127.369 1.619l18.784-18.872L.6 2.712zm20.52 20.562L2.36 42.124c.496.23 1.026.33 1.556.33a3.66 3.66 0 0 0 1.895-.51l21.453-12.511-6.145-6.16v.001zm14.31-4.89-.023-.013-5.956-3.468-6.603 6.633 6.606 6.62 5.976-3.484a3.653 3.653 0 0 0 1.768-3.144 3.653 3.653 0 0 0-1.768-3.144z" fill="#302C40"/>
  </svg>;
}

function AppStore() {
  return <svg width="34" height="43" viewBox="0 0 34 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.253 42.077C5.183 42.042.163 29.34.163 22.872c0-10.567 7.732-12.88 10.712-12.88 1.343 0 2.776.54 4.04 1.02.885.332 1.8.677 2.308.677.306 0 1.022-.293 1.657-.55 1.35-.55 3.031-1.237 4.988-1.237h.013c1.46 0 5.892.33 8.557 4.432l.624.961-.898.695c-1.284.993-3.624 2.804-3.624 6.392 0 4.248 2.65 5.881 3.924 6.668.564.346 1.146.704 1.146 1.487 0 .51-3.976 11.477-9.75 11.477-1.414 0-2.412-.435-3.292-.82-.892-.387-1.66-.723-2.93-.723-.644 0-1.459.314-2.32.644-1.179.45-2.513.963-4.027.963h-.038v-.001zM24.481.27c.15 5.559-3.728 9.415-7.6 9.172C16.242 5.007 20.752.27 24.48.27h.001z" fill="#302C40"/>
  </svg>;
}

function BgTop() {
  return <svg className="bg-top" viewBox="0 0 920 486" fill="none">
    <path fill="url(#07dfrecssa)" d="M0 0h920v486H0z"/>
    <defs>
        <radialGradient id="07dfrecssa" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="rotate(124.462 399.608 210.413) scale(398.98 677.468)">
            <stop stopColor="#B7FFCF" stopOpacity=".32"/>
            <stop offset=".775" stopColor="#B7FFCF" stopOpacity=".05"/>
            <stop offset="1" stopColor="#B7FFCF" stopOpacity="0"/>
        </radialGradient>
    </defs>
  </svg>;
}

function BgBottom() {
  return <svg className="bg-bottom" viewBox="0 0 1219 445" fill="none">
    <path transform="rotate(-180 1219 445)" fill="url(#bo0r55ef2a)" d="M1219 445h1219v445H1219z"/>
    <defs>
        <radialGradient id="bo0r55ef2a" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="rotate(139.793 1137.561 668.675) scale(644.413 1022.83)">
            <stop stopColor="#FD7E22" stopOpacity=".33"/>
            <stop offset=".351" stopColor="#FD7E22" stopOpacity=".09"/>
            <stop offset=".637" stopColor="#FD7E22" stopOpacity="0"/>
            <stop offset="1" stopColor="#FD7E22" stopOpacity="0"/>
        </radialGradient>
    </defs>
  </svg>;
}
