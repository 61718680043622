import React from "react";
import AppContext from "../contexts/AppContext";
import ErrorView from "../components/ErrorView";
import {generatePath} from "react-router";
import routes from "../routes";

export default class ErrorPage extends React.Component {

  componentDidMount() {
    this.context.hideLoader();
  }

  render() {
    return <ErrorView
      error={{type: "photolab", code: 1}}
      onStartOver={() => this.props.history.replace(generatePath(routes.INDEX), {view: "search"})}
    />;
  }
}

ErrorPage.contextType = AppContext;