import React from "react";
import {hitEvent, hits, logEvent, userEvents} from "../utils/log";
import ErrorView from "./ErrorView";
// import * as Sentry from "@sentry/react";

export default class ErrorBoundary extends React.Component {

  state = {
    error: null,
  }

  static getDerivedStateFromError(error) {
    return {error};
  }

  componentDidCatch(error, errorInfo) {
    hitEvent(hits.COMPONENT_ERROR);
    logEvent(userEvents.COMPONENT_ERROR, {
      message: error.message,
      stack: errorInfo.componentStack,
      url: window.location.href,
    });

    // Sentry.captureException(error);
  }

  render() {
    return this.state.error
      ? <ErrorView error={this.state.error} />
      : this.props.children;
  }
}