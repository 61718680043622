window.dataLayer = window.dataLayer || [];

export function gtag() {
  window.dataLayer.push(arguments);

  if (window.appConfig.isDebug) {
    console.log("gtag", arguments);
  }
}

gtag("js", new Date());
gtag("config", window.appConfig.googleAnalytics.trackerId, {
  "custom_map": {
    "dimension1": "user_split_group",
  }
});

if (window.appConfig.googleAnalytics.isEnabled) {
  const script = document.createElement("script");
  script.src = "https://www.googletagmanager.com/gtag/js?id=" + window.appConfig.googleAnalytics.trackerId;
  script.async = true;

  window.document.body.appendChild(script);
}