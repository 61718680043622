import genderHandler from "./gender.handler";
import comboHandler from "./combo.handler";
import dummyHandler from "./dummy.handler";

export const handlersNames = {
  GENDER: "gender",
  COMBO: "combo",
  DUMMY: "dummy",
};

const handlersMap = {
  [handlersNames.GENDER]: genderHandler,
  [handlersNames.COMBO]: comboHandler,
  [handlersNames.DUMMY]: dummyHandler,
};

export function getHandlerByName(name) {
  return handlersMap[name] || null;
}