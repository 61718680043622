import React from "react";
import routes from "../routes";
import AppContext from "../contexts/AppContext";
import {logEvent, userEvents} from "../utils/log";
import {goToProcessing} from "../utils/url";
import ErrorView from "../components/ErrorView";
import processingManager from "../photolab/ProcessingManager";
import {generatePath} from "react-router";
import uploadHandler from "../utils/upload.handler";
import Processing from "../photolab/Processing";

export default class UploadPage extends React.Component {
  state = {
    error: null,
  };

  componentDidMount() {
    logEvent(userEvents.PAGE, {page: "upload"});

    const loadedUrl = new URL(window.location.href);
    const locationState = this.props.location.state || {};

    const fileUrl = loadedUrl.searchParams.has("file_url")
      ? decodeURIComponent(loadedUrl.searchParams.get("file_url"))
      : null;

    const file = fileUrl || locationState.file;

    if (file) {
      this.createFile(file);
    } else {
      this.props.history.replace(generatePath(routes.INDEX));
    }
  }

  createFile = (file) => {
    uploadHandler(file)
      .then((result) => {
        this.handleFileUploaded(result);
      })
      .catch((error) => {
        this.setState(
          {error},
          this.context.hideLoader
        );
      });
  };

  handleFileSelected = (file) => {
    logEvent(userEvents.PHOTO_SELECT, {page: "upload"});

    this.context.showLoader(true, null, null, () => {
      this.setState({error: null}, () => {
        this.createFile(file);
      });
    });
  };

  handleFileUploaded = (file) => {
    // hitEvent(hits.PHOTO_UPLOADED);
    logEvent(userEvents.PHOTO_UPLOADED, {
      file_id: file.id,
      file_url: file.url,
      uploaded_at: Date.now(),
    });

    this.context.showLoader(true, file.url);

    goToProcessing(
      this.props.history,
      Processing.STEP_RESULT,
      file,
      processingManager.processing.layoutFile,
      processingManager.processing.getExtra(Processing.EXTRA_TEXT, ""),
    );
  }

  render() {
    if (this.state.error) {
      return <ErrorView
        error={this.state.error}
        onFileSelected={this.handleFileSelected}
        onStartOver={() => this.props.history.replace(generatePath(routes.INDEX), {view: "search"})}
      />;
    }

    return <React.Fragment />;
  }
}

UploadPage.contextType = AppContext;
