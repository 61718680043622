/* eslint-disable no-unused-vars */

import Creative from "../Creative";
import groups from "./groups";
import {getCreativesInRandomOrder} from "./helpers";
import CreativeConfig from "../CreativeConfig";
import {handlersNames} from "../handlers";
import clientStorage from "../../utils/client-storage";
import Processing from "../Processing";
import {assetUrl} from "../../utils/etc";

const seeds = clientStorage.getArtSeeds(20, 1, 500);
const phrasesPatterns = [
  "{user} {halloween} painting with pumpkin",
  "{user} {halloween} oil painting with pumpkin",
  "{user} {halloween} oil art masterpiece with horror",
  "{user} {halloween} oil painting in spiderwebs",
];

function getLayoutGroups() {
  let list = [
    groups.LAYOUT_TAB_1,
    groups.LAYOUT_TAB_2,
    groups.LAYOUT_TAB_3,
    groups.LAYOUT_TAB_4,
  ].shuffle();

  return list;
}

function getResultGroups() {
  let list = [
    groups.RESULT_TAB_1,
    groups.RESULT_TAB_2,
    groups.RESULT_BODIES,
    groups.RESULT_ATTACH,
  ].shuffle();

  list.unshift(groups.RESULT_ART);

  return list;
}

function getResultWithoutFileGroups() {
  let list = [
    groups.RESULT_WITHOUT_FILE_TAB_1,
    groups.RESULT_WITHOUT_FILE_TAB_2,
    groups.RESULT_WITHOUT_FILE_BODIES,
    groups.RESULT_WITHOUT_FILE_ATTACH,
  ].shuffle();

  list.unshift(groups.RESULT_ART);

  return list;
}

function getCommonCreatives() {
  return [
    // new CreativeConfig(groups.COMMON, "gender", handlersNames.GENDER),
  ];
}

function getLayoutTab1Creatives() {
  const seed = seeds[0];

  return [
    new CreativeConfig(groups.LAYOUT_TAB_1, "sd_v1_4", handlersNames.COMBO)
      .setExtra(Creative.EXTRA_PREVIEW_URL, null)
      .setExtra(Creative.EXTRA_AI_SEED, seed)
      .setExtra(Creative.EXTRA_TEXT_PATTERN, phrasesPatterns[0])
      .setExtra(Creative.EXTRA_COMBO_STEPS, [
        {type: "sd", seed, setAsFile: "raw"},
      ]),
  ];
}

function getLayoutTab2Creatives() {
  const seed = seeds[1];

  return [
    new CreativeConfig(groups.LAYOUT_TAB_2, "sd_v1_4", handlersNames.COMBO)
      .setExtra(Creative.EXTRA_PREVIEW_URL, null)
      .setExtra(Creative.EXTRA_AI_SEED, seed)
      .setExtra(Creative.EXTRA_TEXT_PATTERN, phrasesPatterns[1])
      .setExtra(Creative.EXTRA_COMBO_STEPS, [
        {type: "sd", seed, setAsFile: "raw"}
      ]),
  ];
}

function getLayoutTab3Creatives() {
  const seed = seeds[2];

  return [
    new CreativeConfig(groups.LAYOUT_TAB_3, "sd_v1_4", handlersNames.COMBO)
      .setExtra(Creative.EXTRA_PREVIEW_URL, null)
      .setExtra(Creative.EXTRA_AI_SEED, seed)
      .setExtra(Creative.EXTRA_TEXT_PATTERN, phrasesPatterns[2])
      // .setExtra(Creative.EXTRA_TEXT_PAINTERS, ["van gogh", "gauguin", "rembrandt", "degas"])
      .setExtra(Creative.EXTRA_COMBO_STEPS, [
        {type: "sd", seed, setAsFile: "raw"}
      ]),
  ];
}

function getLayoutTab4Creatives() {
  const seed = seeds[3];

  return [
    new CreativeConfig(groups.LAYOUT_TAB_4, "sd_v1_4", handlersNames.COMBO)
      .setExtra(Creative.EXTRA_PREVIEW_URL, null)
      .setExtra(Creative.EXTRA_AI_SEED, seed)
      .setExtra(Creative.EXTRA_TEXT_PATTERN, phrasesPatterns[3])
      // .setExtra(Creative.EXTRA_TEXT_PAINTERS, ["rubens", "gauguin"])
      .setExtra(Creative.EXTRA_COMBO_STEPS, [
        {type: "sd", seed, setAsFile: "raw"}
      ]),
  ];
}

function getResultArtCreatives() {
  return [
    new CreativeConfig(groups.RESULT_ART, "art", handlersNames.COMBO)
      .setExtra(Creative.EXTRA_PREVIEW_URL, null)
      .setExtra(Creative.EXTRA_COMBO_STEPS, [
        {
          type: "watermark",
          image: "@layoutFile",
          watermark: {
            url: assetUrl("assets/watermarks/1.png"),
            x: 0,
            y: 0,
            percentage: 33,
          },
          setAsFile: "raw",
        },
      ]),
  ];
}

function getResultTab1Creatives() {
  return [
    new CreativeConfig(groups.RESULT_TAB_1, "7436", handlersNames.COMBO)
      .setExtra(Creative.EXTRA_PREVIEW_URL, null)
      .setExtra(Creative.EXTRA_COMBO_STEPS, [
        {id: 6756},
        {
          id: 7436,
          images: [
            {src: "#"},
            {src: "@layoutFile"},
          ],
        },
        {type: "text1", setAsFile: "raw"},
      ]),
  ];
}

function getResultTab2Creatives() {
  return [
    new CreativeConfig(groups.RESULT_TAB_2, "7437", handlersNames.COMBO)
      .setExtra(Creative.EXTRA_PREVIEW_URL, null)
      .setExtra(Creative.EXTRA_COMBO_STEPS, [
        {id: 6756},
        {
          id: 7437,
          images: [
            {src: "#"},
            {src: "@layoutFile"},
          ],
        },
        {type: "text2", setAsFile: "raw"},
      ]),
  ];
}

function getResultAttachCreatives() {
  return [
    new CreativeConfig(groups.RESULT_ATTACH, "7414", handlersNames.COMBO)
      .setExtra(Creative.EXTRA_PREVIEW_URL, null)
      .setExtra(Creative.EXTRA_ATTACH_FILE, "pending")
      .setExtra(Creative.EXTRA_COMBO_STEPS, [
        {id: 6756, images: [{src: "@selfieMessageFile"}]}, // quick fix
        {id: 6756, images: [{src: "@"}]},
        {
          id: 7414,
          images: [
            {src: "#1"},
            {src: "@layoutFile"}, // quick fix
            {src: "#0"},
          ],
        },
        {type: "text3", setAsFile: "raw"},
      ]),
  ];
}

function getResultBodiesCreatives() {
  const bodies = [
    7451, 7453, 7457, 7440, 7441, 7444, 7445, 7452,
  ];

  const bodyId = bodies.shuffle().first();

  return [
    new CreativeConfig(groups.RESULT_BODIES, bodyId, handlersNames.COMBO)
      .setExtra(Creative.EXTRA_PREVIEW_URL, null)
      .setExtra(Creative.EXTRA_COMBO_STEPS, [
        {id: 6756},
        {id: bodyId, images: [{src: "#"}, {src: "@layoutFile"}]},
        {
          type: bodyId === 7438 ? "text5" : "text4",
          setAsFile: "raw",
        },
      ]),
  ]
}

function getResultWithoutFileTab1Creatives() {
  return [
    new CreativeConfig(groups.RESULT_WITHOUT_FILE_TAB_1, "7448", handlersNames.COMBO)
      .setExtra(Creative.EXTRA_PREVIEW_URL, null)
      .setExtra(Creative.EXTRA_COMBO_STEPS, [
        {id: 7448, images: [{src: "@layoutFile"}]},
        {type: "text1w", setAsFile: "raw"},
      ]),
  ];
}

function getResultWithoutFileTab2Creatives() {
  return [
    new CreativeConfig(groups.RESULT_WITHOUT_FILE_TAB_2, "7447", handlersNames.COMBO)
      .setExtra(Creative.EXTRA_PREVIEW_URL, null)
      .setExtra(Creative.EXTRA_COMBO_STEPS, [
        {id: 7447, images: [{src: "@layoutFile"}]},
        {type: "text2w", setAsFile: "raw"},
      ]),
  ];
}

function getResultWithoutFileAttachCreatives() {
  return [
    new CreativeConfig(groups.RESULT_WITHOUT_FILE_ATTACH, "attach", handlersNames.DUMMY)
      .setExtra(Creative.EXTRA_DUMMY, true),
  ];
}

function getResultWithoutFileBodiesCreatives() {
  return [
    new CreativeConfig(groups.RESULT_WITHOUT_FILE_BODIES, "body", handlersNames.DUMMY)
      .setExtra(Creative.EXTRA_DUMMY, true),
  ];
}

export default {
  getGroups: function(step) {
    switch (step) {
      case Processing.STEP_LAYOUT: {
        return getLayoutGroups();
      }
      case Processing.STEP_RESULT: {
        return getResultGroups();
      }
      case Processing.STEP_RESULT_WITHOUT_FILE: {
        return getResultWithoutFileGroups();
      }
      default: {
        throw new Error(`Unrecognized processing step: '${step}'.`);
      }
    }
  },

  getCreatives: function() {
    let listOfAll = [
      ...getLayoutTab1Creatives(),
      ...getLayoutTab2Creatives(),
      ...getLayoutTab3Creatives(),
      ...getLayoutTab4Creatives(),
      ...getResultArtCreatives(),
      ...getResultTab1Creatives(),
      ...getResultTab2Creatives(),
      ...getResultBodiesCreatives(),
      ...getResultAttachCreatives(),
      ...getResultWithoutFileTab1Creatives(),
      ...getResultWithoutFileTab2Creatives(),
      ...getResultWithoutFileAttachCreatives(),
      ...getResultWithoutFileBodiesCreatives(),
    ];

    if (window.clientConfig.features.tabsWithRandomOrder) {
      listOfAll = [
        ...getCreativesInRandomOrder(getLayoutTab1Creatives()),
        ...getCreativesInRandomOrder(getLayoutTab2Creatives()),
        ...getCreativesInRandomOrder(getLayoutTab3Creatives()),
        ...getCreativesInRandomOrder(getLayoutTab4Creatives()),
        ...getCreativesInRandomOrder(getResultArtCreatives()),
        ...getCreativesInRandomOrder(getResultTab1Creatives()),
        ...getCreativesInRandomOrder(getResultTab2Creatives()),
        ...getCreativesInRandomOrder(getResultBodiesCreatives()),
        ...getCreativesInRandomOrder(getResultAttachCreatives()),
        ...getCreativesInRandomOrder(getResultWithoutFileTab1Creatives()),
        ...getCreativesInRandomOrder(getResultWithoutFileTab2Creatives()),
        ...getCreativesInRandomOrder(getResultWithoutFileAttachCreatives()),
        ...getCreativesInRandomOrder(getResultWithoutFileBodiesCreatives()),
      ];
    }

    return [
      ...getCommonCreatives(),
      ...listOfAll,
    ];
  },
};
