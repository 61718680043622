import React from "react";

const AppContext = React.createContext({
  loader: {},
  toast: {
    message: "",
    delay: 5000,
  },
  installedApps: {},
  modals: [],
});

const AppContextConsumer = AppContext.Consumer;

class AppContextProvider extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loader: {
        isHidden: true,
        image: null,
        hideText: true,
        className: null,
      },
      toast: {
        message: "",
        delay: 5000,
      },
      installedApps: {},
      modals: [],
    };
  }

  render() {
    const state = {
      ...this.state,
      setInstalledApps: (apps) => {
        this.setState({installedApps: apps});
      },
      pushModal: (component, cb) => {
        const modals = this.state.modals.slice();
        modals.push(component);

        this.setState({modals}, () => cb && cb());
      },
      popModal: (cb) => {
        const modals = this.state.modals.slice();
        modals.pop();

        this.setState({modals}, () => cb && cb());
      },
      hideLoader: (cb) => {
        const nextState = this.state.loader;
        nextState.isHidden = true;

        this.setState({loader: nextState},() => cb && cb());
      },
      showLoader: (showTexts = true, imageUrl = null, className = null, cb) => {
        this.setState({loader: {
          isHidden: false,
          image: imageUrl,
          hideText: !showTexts,
          className,
        }},() => cb && cb());
      },
      showToast: ({message, delay}) => {
        this.setState({
          toast: {
            messageKey: Date.now(),
            message,
            delay,
          },
        });
      }
    };

    return <AppContext.Provider
      value={state}
      children={this.props.children}
    />;
  }
}

export default AppContext;

export {AppContextConsumer, AppContextProvider};
