import {generatePath} from "react-router";
import routes from "../routes";
import * as api from "./api";
import {hitEvent, hits} from "./log";

export function goToProcessing(history, step= null, file = null, layoutFile = null, text = "") {
  if (window.clientConfig.isWebviewIOS || true) {
    const processingUrl = new URL(window.location.href);
    processingUrl.pathname = generatePath(routes.PROCESSING, {hash: Date.now()});

    if (step) {
      processingUrl.searchParams.append("step", encodeURIComponent(step));
    }

    if (file) {
      processingUrl.searchParams.append("file_url", encodeURIComponent(file.url));
    }

    if (layoutFile) {
      processingUrl.searchParams.append("layout_file_url", encodeURIComponent(layoutFile.url));
    }

    if (text) {
      processingUrl.searchParams.append("text", encodeURIComponent(text));
    }

    Object.keys(window.clientConfig.webviewParams).forEach((key) => {
      processingUrl.searchParams.append(key, window.clientConfig.webviewParams[key]);
    });

    api.getBuildInfo()
      .then((result) => {
        if (parseInt(window.appConfig.build.version) < parseInt(result.version)) {
          window.location.replace(processingUrl.toString());
          hitEvent(hits.FORCE_REDIRECT_PROCESSING);
        } else {
          history.replace({
            pathname: generatePath(routes.PROCESSING),
            state: {step, file, layoutFile, text},
          });
        }
      })
      .catch((err) => {
        window.location.replace(processingUrl.toString());
        hitEvent(hits.FORCE_REDIRECT_PROCESSING);
      });

    return;
  }

  history.replace({
    pathname: generatePath(routes.PROCESSING),
    state: {step, file, layoutFile, text},
  });
}

