import React from "react";
import PropTypes from "prop-types";
import i18n from "../../i18n";
import {getCreativesConfigs} from "../../photolab/config";
import Creative from "../../photolab/Creative";
import CreativeView from "./CreativeView";
import {hitEvent, hits, logEvent, userEvents} from "../../utils/log";
import CountdownLoaderSvg from "../../components/CountdownLoaderSvg";
import {creativeIsNew, creativeName, resolveCreativeImageFile, resolveCreativePreviewFile} from "../../utils/creative";
import AppContext from "../../contexts/AppContext";
import Processing from "../../photolab/Processing";
import clientStorage from "../../utils/client-storage";
import clientSessionStorage from "../../utils/client-session-storage";
import WatermarkModal from "../../components/WatermarkModal";
import processingManager from "../../photolab/ProcessingManager";
import UploadSelfieModal from "../../components/UploadSelfieModal";
import {fileToJson, webviewAnalyticsEvent} from "../../utils/webview";
import CreativeAttachFileView from "./CreativeAttachFileView";
import CreativeDummyView from "./CreativeDummyView";

export default class TabContentView extends React.Component {

  state = {};

  componentDidMount() {
    logEvent(userEvents.TAB_SELECT, {
      group: this.props.group,
      gender: processingManager.processing.getGender(),
      is_new_client: window.clientConfig.isNew,
    });

    this.startCreativeIfNeeded();
  }

  handleDownloadClick = (creative) => {
    const processing = processingManager.processing;

    const positionInGroup = processing
      .getCreativesInGroup(creative.group)
      .findIndex(c => c.id === creative.id);

    hitEvent(hits.DOWNLOAD);
    hitFirstDownloadByClient();
    hitFirstDownloadByProcessing(processing);
    hitFirstDownloadByTemplate(creative);

    const step = processingManager.processing.getExtra(Processing.EXTRA_STEP);
    const page = step === Processing.STEP_LAYOUT ? "layout"
      : (step === Processing.STEP_RESULT_WITHOUT_FILE ? "result_without_file" : "result");

    logEvent(userEvents.DOWNLOAD, {
      group: creative.group,
      gender: processing.getGender(),
      template_id: creativeName(creative),
      alias: creative.getExtra(Creative.EXTRA_ALIAS, ""),
      position: processing.groups.indexOf(creative.group) + 1,
      position_in_group: positionInGroup + 1,
      is_refresh: creative.isRefreshed,
      is_new_client: window.clientConfig.isNew,
      text: processing.getExtra(Processing.EXTRA_TEXT, ""),
      text_result: creative.getExtra(Creative.EXTRA_TEXT_RESULT, processing.getExtra(Creative.EXTRA_TEXT_RESULT, "")),
      text_append: creative.getExtra(Creative.EXTRA_TEXT_APPEND, processing.getExtra(Creative.EXTRA_TEXT_APPEND, "")),
      ai_seed: creative.getExtra(Creative.EXTRA_AI_SEED, processing.getExtra(Creative.EXTRA_AI_SEED, -1)),
      ai_group: processing.getExtra(Processing.EXTRA_AI_GROUP, ""),
      page: page,
    });

    this.props.onDownloadClick(creative);
  };

  handleRetryClick = (creative) => {
    const config = getCreativesConfigs().find((config) => config.templateId === creative.templateId);

    const newCreative = new Creative()
      .configureByConfig(config)
      .setAsSelected(true);

    processingManager.processing.replaceCreative(creative, newCreative);

    this.props.onProcessingChanged();
    processingManager.update();
  };

  handleTabClick = (creative) => {
    const processing = processingManager.processing;

    hitFirstClickRefreshCreative(processing, creative);

    logEvent(userEvents.TAB_REFRESH, {
      group: creative.group,
      gender: processing.getGender(),
      template_id: creativeName(creative),
      is_new_client: window.clientConfig.isNew,
    });

    const currentSelectedCreative = processing.getSelectedCreativeInGroup(this.props.group);
    currentSelectedCreative && currentSelectedCreative.setAsSelected(false);

    if (window.clientConfig.features.isAutoStartCreatives) {
      if (!creative.getExtra(Creative.EXTRA_VIEW_OPENED, false)) {
        //showNativeAds();
      }
    }

    creative.setExtra(Creative.EXTRA_SELECTED_AT, Date.now());
    creative.setExtra(Creative.EXTRA_VIEW_OPENED, true);
    creative.setAsSelected(true);

    if (creative.hasExtra(Creative.EXTRA_KEEP_PENDING)) {
      creative.removeExtra(Creative.EXTRA_KEEP_PENDING);
      hitEvent(hits.START_PENDING_CREATIVE);
      //showNativeAds();
    }

    this.props.onProcessingChanged();
    processingManager.update();
  };

  startCreativeIfNeeded = () => {
    const creative = processingManager.processing.getSelectedCreativeInGroup(this.props.group);
    if (!creative) {
      return;
    }

    if (window.clientConfig.features.isAutoStartCreatives) {
      if (!creative.getExtra(Creative.EXTRA_VIEW_OPENED, false)) {
        creative.setExtra(Creative.EXTRA_VIEW_OPENED, true);
        //showNativeAds();
      }
    }

    if (creative.hasExtra(Creative.EXTRA_KEEP_PENDING)) {
      creative.removeExtra(Creative.EXTRA_KEEP_PENDING);
      hitEvent(hits.START_PENDING_CREATIVE);
      //showNativeAds();
    }

    this.props.onProcessingChanged();
    processingManager.update();
  };

  handleImageLoaded = (creative) => {
    processingManager.processing.setPreview(creative.group, resolveCreativeImageFile(creative));
    processingManager.update();
  }

  handleShowWatermark = () => {
    processingManager.processing.setExtra(Processing.EXTRA_PRO_WATERMARK_SHOULD_BE_REMOVED, false);

    this.props.onProcessingChanged();
    processingManager.update();
  }

  handleHideWatermark = () => {
    processingManager.processing.setExtra(Processing.EXTRA_PRO_WATERMARK_SHOULD_BE_REMOVED, true);

    this.props.onProcessingChanged();
    processingManager.update();
  }

  showWatermarkModal = () => {
    clientSessionStorage.setProWatermarkTooltipIsHidden(true);

    if (clientStorage.getProWatermarkShowModal()%3 === 0) {
      this.context.pushModal(<WatermarkModal
        key="ResultPage_WatermarkModal"
        onCancelButtonClick={this.handleShowWatermark}
        onRemoveButtonClick={this.handleHideWatermark}
      />);
    } else {
      this.handleHideWatermark();
    }

    clientStorage.incrementProWatermarkShowModal();
  }

  showUploadSelfieModal = (creative, place) => {
    this.context.pushModal(<UploadSelfieModal
      key="ResultPage_UploadSelfieModal"
      onDownloadClick={() => this.handleDownloadClick(creative)}
      onFileSelected={(file, isRecent) => this.props.onFileSelected(file, isRecent, place)}
      onSkipClick={this.props.onForwardClick}
    />);
  }

  handleProceedClick = (creative) => {
    const processing = processingManager.processing;
    processing.setLayoutFile(fileToJson(creative.getFile("raw")));

    const file = processing.getExtra(Processing.EXTRA_PROCESSING_FILE, null);

    processing.setExtra(
      Creative.EXTRA_TEXT_RESULT,
      creative.getExtra(Creative.EXTRA_TEXT_RESULT, "")
    );

    processing.setExtra(
      Creative.EXTRA_TEXT_APPEND,
      creative.getExtra(Creative.EXTRA_TEXT_APPEND, "")
    );

    processing.setExtra(
      Creative.EXTRA_AI_SEED,
      creative.getExtra(Creative.EXTRA_AI_SEED, -1)
    );

    processing.setExtra(Processing.EXTRA_AI_GROUP, creative.group);

    logEvent(userEvents.PROCEED_BUTTON_CLICK);

    webviewAnalyticsEvent("art_screen_proceed",
      processing.getExtra(Processing.EXTRA_TEXT),
      creative.getExtra(Creative.EXTRA_AI_SEED, -1),
      creative.getExtra(Creative.EXTRA_TEXT_RESULT, ""),
      creative.getExtra(Creative.EXTRA_TEXT_APPEND, "")
    );

    if (file) {
      this.props.onFileSelected(file);
    } else {
      this.showUploadSelfieModal(creative, "art");
    }
  }

  render() {
    const processing = processingManager.processing;

    const groupCreatives = processing.getCreativesInGroup(this.props.group);
    if (groupCreatives.length === 0) {
      return <React.Fragment />;
    }

    const creative = groupCreatives.find((c) => c.isSelected);
    const fakeWatermark = false; //!processingManager.processing.getExtra(Processing.EXTRA_PRO_WATERMARK_SHOULD_BE_REMOVED, false);
    const isShowSubTabs = groupCreatives.length > 1;
    const processingText = processing.getExtra(Processing.EXTRA_TEXT, "");
    const creativeWaitingFile = creative && creative.hasExtra(Creative.EXTRA_ATTACH_FILE);
    const creativeIsDummy = creative && creative.hasExtra(Creative.EXTRA_DUMMY);

    const isLayoutStep = processing.getExtra(Processing.EXTRA_STEP) === Processing.STEP_LAYOUT;

    return <React.Fragment>
      <div className="creative-block">
        {creative && !creativeWaitingFile && !creativeIsDummy && <CreativeView
          key={resolveCreativeImageFile(creative)}
          creative={creative}
          onRetryClick={() => this.handleRetryClick(creative)}
          showUploadSelfieModal={() => this.showUploadSelfieModal(creative, "template")}
          onImageLoaded={() => this.handleImageLoaded(creative)}
          fakeWatermark={fakeWatermark}
          onHideWatermarkButtonClick={this.showWatermarkModal}
        />}

        {creativeWaitingFile && <CreativeAttachFileView
          key={resolveCreativeImageFile(creative)}
          creative={creative}
          onRetryClick={() => this.handleRetryClick(creative)}
          onImageLoaded={() => this.handleImageLoaded(creative)}
          fakeWatermark={fakeWatermark}
          onHideWatermarkButtonClick={this.showWatermarkModal}
          onFileSelected={this.props.onFileSelected}
        />}

        {creativeIsDummy && <CreativeDummyView
          creative={creative}
          onFileSelected={this.props.onFileSelected}
        />}

        {isShowSubTabs && <div className="creative-container">
          <div className={`creative-tabs-container`}>
            <div className={`creative-tabs`}>
              {groupCreatives.map((c, i) => <RefreshTabView
                key={i}
                creative={c}
                onClick={() => this.handleTabClick(c)}
              />)}
            </div>
          </div>
        </div>}
      </div>

      {isLayoutStep && <div className="processing-text-container">
        <p className="processing-text">{processingText}</p>
        <svg viewBox="0 0 30 27" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M4.5 0H1a1 1 0 0 0-1 1v11.5a1 1 0 0 0 1 1h2.5a1 1 0 0 1 1 1V26a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V0h-9zM20.699 0h-3.5a1 1 0 0 0-1 1v11.5a1 1 0 0 0 1 1h2.5a1 1 0 0 1 1 1V26a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V0h-9z" fill="#605D6C"/>
        </svg>
      </div>}

      <div className="btns-container btns-container-creative">
        <button
          className="btn-upload btn-empty"
          onClick={this.props.onStartClick}>
          {i18n.t("btn_start_again")}
        </button>

        {!isLayoutStep && <button
          style={{visibility: (creative && creative.isProcessed && !creativeIsDummy ? "visible" : "hidden")}}
          className="creative-download"
          dangerouslySetInnerHTML={{__html: i18n.t("result__creative_download")}}
          onClick={() => this.handleDownloadClick(creative)}
        />}

        {isLayoutStep && <button
          style={{visibility: (creative && creative.isProcessed ? "visible" : "hidden")}}
          className="creative-download"
          dangerouslySetInnerHTML={{__html: i18n.t("btn_proceed")}}
          onClick={() => this.handleProceedClick(creative)}
        />}
      </div>
    </React.Fragment>;
  }
}

TabContentView.propTypes = {
  group: PropTypes.string.isRequired,
}

TabContentView.contextType = AppContext;

class RefreshTabView extends React.Component {

  render() {
    const creative = this.props.creative;
    const isShowLoader = creative
      && creative.isPending
      && creative.getExtra(Creative.EXTRA_KEEP_PENDING, false) !== true;

    const isNewCreative = creativeIsNew(creative);

    const classNames = [];
    isNewCreative && classNames.push("new");

    creative && creative.isSelected && classNames.push("active");
    this.props.className && classNames.push(this.props.className);

    const style = {};
    if (creative) {
      const preview = resolveCreativePreviewFile(creative);

      if (preview) {
        style.backgroundImage = `url(${preview})`;
      }
    }

    return <button
      className={classNames.join(" ")}
      style={style}
      onClick={this.props.onClick}>
      <div className="timer-loader">
        {isShowLoader && <CountdownLoaderSvg duration={10} />}
      </div>
      {isNewCreative && <span dangerouslySetInnerHTML={{__html: i18n.t("label_new")}} />}
    </button>;
  }
}

function hitFirstClickRefreshCreative(processing, creative, hit = true) {
  const cacheKey = `refresh_creative_first_click:${processing.id}_${creative.group}_${creative.templateId}`;

  if (!window.appGlobalCache[cacheKey]) {
    window.appGlobalCache[cacheKey] = true;
    if (hit) {
      hitEvent(hits.FIRST_CLICK_REFRESH_CREATIVE);
    }
  }
}

function hitFirstDownloadByTemplate(creative) {
  if (!creative.getExtra(Processing.EXTRA_FIRST_DOWNLOAD_AT)) {
    hitEvent(hits.FIRST_DOWNLOAD_BY_TEMPLATE);

    creative.setExtra(Processing.EXTRA_FIRST_DOWNLOAD_AT, Date.now());
    processingManager.update();
  }
}

function hitFirstDownloadByProcessing(processing) {
  if (!processing.getExtra(Processing.EXTRA_FIRST_DOWNLOAD_AT)) {
    hitEvent(hits.FIRST_DOWNLOAD_BY_PROCESSING);

    processing.setExtra(Processing.EXTRA_FIRST_DOWNLOAD_AT, Date.now());
    processingManager.update();
  }
}

function hitFirstDownloadByClient() {
  if (!clientStorage.getFirstDownloadAt()) {
    hitEvent(hits.FIRST_DOWNLOAD_BY_CLIENT);

    clientStorage.setFirstDownloadAt(Date.now());
  }
}
