import React from "react";

export default class WindowSizeWatcher extends React.Component {

  componentDidMount() {
    this.windowHeight = 0;
    this.onVisibilityChangeListener();

    document.addEventListener("visibilitychange", this.onVisibilityChangeListener);
  }

  componentWillUnmount() {
    document.removeEventListener("visibilitychange", this.onVisibilityChangeListener);
  }

  onVisibilityChangeListener = () => {
    if (document.visibilityState === "visible") {
      this.onResume();
    } else {
      this.onPause();
    }
  };

  onResume = () => {
    this.windowHeightTimer = setInterval(this.updateWindowHeight, 1000/5);
  };

  onPause = () => {
    clearInterval(this.windowHeightTimer);
  };

  updateWindowHeight = () => {
    if (this.windowHeight !== window.innerHeight) {
      this.windowHeight = window.innerHeight;
      document.documentElement.style.setProperty("--window-inner-height", this.windowHeight + "px");
    }
  };

  render() {
    return <React.Fragment />;
  }
}