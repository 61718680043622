import React from "react";

export default class AppToast extends React.Component {

  state = {
    hidden: true,
  };

  timer = null;

  componentWillUpdate(nextProps, nextState, nextContext) {
    if (this.props.messageKey !== nextProps.messageKey) {
      this.show();
    }
  }

  show = () => {
    clearTimeout(this.timer);
    this.timer = null;

    this.setState({hidden: false}, () => {
      this.timer = setTimeout(this.hide, this.props.delay);
    });
  };

  hide = () => {
    clearTimeout(this.timer);
    this.timer = null;
    this.setState({hidden: true});
  }

  render() {
    return <div
      hidden={this.state.hidden}
      className="app-toast-top"
      dangerouslySetInnerHTML={{__html: this.props.message}}
      onClick={() => this.setState({hidden: true})}
    />;
  }
}