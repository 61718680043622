export default {
  COMMON: "common",
  LAYOUT_TAB_1: "layout_tab_1",
  LAYOUT_TAB_2: "layout_tab_2",
  LAYOUT_TAB_3: "layout_tab_3",
  LAYOUT_TAB_4: "layout_tab_4",
  RESULT_ART: "result_art",
  RESULT_TAB_1: "result_tab_1",
  RESULT_TAB_2: "result_tab_2",
  RESULT_ATTACH: "result_attach",
  RESULT_BODIES: "result_bodies",
  RESULT_WITHOUT_FILE_TAB_1: "result_without_file_tab_1",
  RESULT_WITHOUT_FILE_TAB_2: "result_without_file_tab_2",
  RESULT_WITHOUT_FILE_ATTACH: "result_without_file_attach",
  RESULT_WITHOUT_FILE_BODIES: "result_without_file_bodies",
};
