import React from "react";
import i18n from "../../i18n";
import PropTypes from "prop-types";
import {creativeIsNew, resolveCreativePreviewFile} from "../../utils/creative";
import Creative from "../../photolab/Creative";
import processingManager from "../../photolab/ProcessingManager";
import Processing from "../../photolab/Processing";
import groups from "../../photolab/config/groups";
import {assetUrl} from "../../utils/etc";

export default function TabView({
  group,
  isActive,
  onClick,
  children,
  className,
  hidden
}) {
  if (hidden) {
    return <React.Fragment />;
  }

  const creative = processingManager.processing.getSelectedCreativeInGroup(group);
  const isShowLoader = creative
    && creative.isPending
    && creative.getExtra(Creative.EXTRA_KEEP_PENDING, false) !== true;

  const hasNewCreativesInGroup = processingManager.processing.getCreativesInGroup(group)
    .findIndex((c) => creativeIsNew(c)) !== -1;

  const style = {};
  const classNames = ["btn-choice-tab"];

  classNames.push(`btn-choice-tab--${group}`);
  isActive && classNames.push("active");

  const creativeWaitingFile = (creative && creative.hasExtra(Creative.EXTRA_ATTACH_FILE))
    && !processingManager.processing.getExtra(Processing.EXTRA_SELFIE_MESSAGE_FILE);

  const creativeIsPending = creative && creative.isPending && !creativeWaitingFile;
  const creativeIsFailed = creative && creative.isFailed;

  creativeIsFailed && classNames.push("error");
  creativeIsPending && classNames.push("waiting");
  hasNewCreativesInGroup && classNames.push("new");
  className && classNames.push(className);

  let preview = resolveCreativePreviewFile(creative);
  if (group === groups.RESULT_WITHOUT_FILE_ATTACH) {
    preview = assetUrl("assets/images/tabs-previews/4.jpg");
  } else if (group === groups.RESULT_WITHOUT_FILE_BODIES) {
    preview = assetUrl("assets/images/tabs-previews/3.jpg");
  }

  if (preview) {
    style.backgroundImage = `url(${preview})`;
  }

  return <button
    className={classNames.join(" ")}
    style={style}
    onClick={onClick}
    children={children}>
    {creativeWaitingFile && <SvgAttachIcon />}
    {creativeIsFailed && <SvgErrorIcon />}
    {creativeIsPending && <div className="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>}
    {hasNewCreativesInGroup && <span dangerouslySetInnerHTML={{__html: i18n.t("label_new")}} />}
  </button>;
}

TabView.propTypes = {
  group: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

function SvgErrorIcon() {
  return <svg viewBox="0 0 24 24" className="error-icon">
    <path d="M12 0a12 12 0 1 0 12 12A12.013 12.013 0 0 0 12 0zm0 3a8.927 8.927 0 0 1 5.2 1.68L4.68 17.198A8.973 8.973 0 0 1 12 3zm0 18a8.928 8.928 0 0 1-5.2-1.68L19.32 6.802A8.973 8.973 0 0 1 12 21z" />
  </svg>;
}

function SvgAttachIcon() {
  return <svg viewBox="0 0 25 28" fill="none" className="error-icon">
    <path d="m1.28 24.657.933-3.392c1.543-5.612 5.62-8.455 9.696-8.455" stroke="#B7FFCF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M11.909 12.81a5.905 5.905 0 1 0 0-11.81 5.905 5.905 0 0 0 0 11.81z" stroke="#B7FFCF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="bevel"/>
    <path d="M17.814 27a5.905 5.905 0 1 0 0-11.81 5.905 5.905 0 0 0 0 11.81zM15.452 21.095h4.724M17.814 18.733v4.724" stroke="#B7FFCF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>;
}