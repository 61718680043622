import React from "react";
import PropTypes from "prop-types";
import i18n from "../../i18n";

export default function CreativeErrorView(props) {
  const error = props.creative.error;
  const isPhotolabError = error.type === "photolab";
  const isPhotolabSystemError = isPhotolabError && error.code > -1000;

  const isNetworkError = (error.type === "network")
    || (error.message === "Network Error")
    || (error.name === "NetworkError")
    || (navigator.onLine === false);

  let message = "";
  if (isNetworkError) {
    message = i18n.t("error__network_message");
  } else if (isPhotolabSystemError) {
    message = i18n.t("error_overload");
  } else if (isPhotolabError) {
    message = error.message;
  } else {
    message = i18n.t("result__creative_failed_message");
  }

  return <div className="error-container">
    <div className="creative-error-message-container">
      <div className="creative-error-message">
        <p>{message}</p>

        <button
          hidden={isPhotolabError && (!isNetworkError)}
          className="btn-retry"
          onClick={props.onRetryClick}>
          {i18n.t("result__creative_retry")}
        </button>
      </div>
    </div>
  </div>;
}

CreativeErrorView.propTypes = {
  creative: PropTypes.object.isRequired,
  onRetryClick: PropTypes.func.isRequired,
}