import React from "react";
import routes from "../routes";
import {hitEvent, hits, logEvent, logProcessingsTimings, userEvents} from "../utils/log";
import AppContext from "../contexts/AppContext";
import {fileToJson} from "../utils/webview";
import {
  getCreativesConfigs,
  resolveCreativeConfigByGroup,
  getCreativesConfigsByGroup,
  getProcessingConfig,
} from "../photolab/config";
import creativeGroups from "../photolab/config/groups";
import Processing from "../photolab/Processing"
import Creative from "../photolab/Creative";
import ErrorView from "../components/ErrorView";
import {resetNativeAdsCounter, showNativeAds} from "../utils/native-ads";
import clientStorage from "../utils/client-storage";
import {resolveCreativePreviewFile} from "../utils/creative";
import processingManager from "../photolab/ProcessingManager";
import {debounce} from "../utils/etc";
import {generatePath} from "react-router";

export default class ProcessingPage extends React.Component {

  state = {
    error: null,
    errorWithUploadForm: true,
  };

  processingTimerId = null;
  processingTimer = 0;

  componentDidMount() {
    document.addEventListener("visibilitychange", this.startProcessingTimer, false);
    processingManager.addOnProcessingChangeHandler(this.handleProcessingChange);

    logEvent(userEvents.PAGE, {page: "processing"});

    const loadedUrl = new URL(window.location.href);
    const stepUrlParam = loadedUrl.searchParams.get("step");
    const fileUrlParam = loadedUrl.searchParams.get("file_url");
    const layoutFileUrlParam = loadedUrl.searchParams.get("layout_file_url");
    const textUrlParam = loadedUrl.searchParams.get("text");
    const locationState = this.props.location.state || {};

    const step = (stepUrlParam && decodeURIComponent(stepUrlParam))
      || locationState.step;

    const file = (fileUrlParam && fileToJson(decodeURIComponent(fileUrlParam)))
      || locationState.file;

    const layoutFile = (layoutFileUrlParam && fileToJson(decodeURIComponent(layoutFileUrlParam)))
      || locationState.layoutFile;

    const text = (textUrlParam && decodeURIComponent(textUrlParam))
      || locationState.text;

    const processing = processingManager.processing;

    if (processing === null) {
      const restoredProcessing = processingManager.restore();

      if ((step === Processing.STEP_LAYOUT && text)
        || (step === Processing.STEP_RESULT_WITHOUT_FILE && layoutFile)
        || (step === Processing.STEP_RESULT && file && layoutFile)
      ) {
        if (step === Processing.STEP_LAYOUT) {
          this.context.showLoader(true, null, "loader-with-upload-form");
        } else if (file) {
          this.context.showLoader(true, file.url);
        } else {
          this.context.showLoader();
        }

        this.startProcessing(step, file, layoutFile, text);
      } else if (restoredProcessing) {
        if (restoredProcessing.file) {
          this.context.showLoader(true, restoredProcessing.file.url);
        } else {
          this.context.showLoader();
        }

        processingManager.start(restoredProcessing);

        this.startProcessingTimer();
      } else {
        this.props.history.replace(generatePath(routes.INDEX));
      }
    } else {
      processing.setExtra(Processing.EXTRA_STEP, step);
      processing.setFile(file);
      processing.setGroups(getProcessingConfig().getGroups(step));
      processing.setExtra(Processing.SELECTED_RESULT_GROUP, null);

      const layoutGroups = getProcessingConfig().getGroups(Processing.STEP_LAYOUT);
      const resultCreatives = processing.creatives.filter((c) => !layoutGroups.includes(c.group));

      resultCreatives.forEach((rc) => {
        processing.removeCreative(rc);
      });

      processingManager.update();

      this.startProcessingTimer();
    }
  }

  componentWillUnmount() {
    this.stopProcessingTimer();

    processingManager.removeOnProcessingChangeHandler(this.handleProcessingChange);
  }

  stopProcessingTimer = () => {
    clearInterval(this.processingTimerId);
    document.removeEventListener("visibilitychange", this.startProcessingTimer, false);
  }

  startProcessingTimer = () => {
    clearInterval(this.processingTimerId);

    if (document.visibilityState !== "visible") {
      return;
    }

    this.processingTimerId = setInterval(() => {
      this.processingTimer++;

      if ((this.processingTimer * 1000) > window.appConfig.processings.timeout) {
        this.stopProcessingTimer();
        this.handleProcessingTimeout();
      }
    }, 1000);
  }

  startProcessing = (step, file, layoutFile, text) => {
    const processing = new Processing();
    processing.setId(Date.now());
    processing.setFile(file);
    processing.setLayoutFile(layoutFile);
    processing.setGroups(getProcessingConfig().getGroups(step));
    processing.setLanguage(window.clientConfig.lang);
    processing.setExtra(Processing.EXTRA_CREATED_AT, Date.now());
    processing.setExtra(Processing.EXTRA_TEXT, text);
    processing.setExtra(Processing.EXTRA_STEP, step);

    getCreativesConfigs()
      .filter((config) => config.group === creativeGroups.COMMON)
      .forEach((config) => {
        processing.addCreative(new Creative()
          .configureByConfig(config));
      });

    const sessionId = parseInt(window.clientConfig.webviewParams.session_idx || 0);
    const [storedSessionId, storedCount] = clientStorage.getCountProcessingInSession();
    const processingNumber = sessionId !== storedSessionId ? 1 : (storedCount + 1);
    clientStorage.setCountProcessingInSession(sessionId, processingNumber);

    switch (step) {
      case Processing.STEP_LAYOUT: {
        hitEvent(hits.PROCESSING_START_ART);
        break;
      }
      case Processing.STEP_RESULT: {
        hitEvent(hits.PROCESSING_START_PHOTO);
        break;
      }
      case Processing.STEP_RESULT_WITHOUT_FILE: {
        hitEvent(hits.PROCESSING_START_NO_PHOTO);
        break;
      }
    }

    processingManager.start(processing);

    this.startProcessingTimer();

    showNativeAds();
  };

  /** @param {Processing} processing */
  addCreatives = (processing) => {
    const startKeepPendingIndex = (window.clientConfig.splitGroupId < 11) ? 1 : 0; // true

    processing.groups.forEach((group, index) => {
      const selectedCreativeConfig = resolveCreativeConfigByGroup(group, processing.getGender());
      if (!selectedCreativeConfig) {
        return;
      }

      const selectedCreative = new Creative()
        .configureByConfig(selectedCreativeConfig)
        .setAsSelected(true);

      const addCreatives = [];
      addCreatives.push(selectedCreative);

      getCreativesConfigsByGroup(group)
        .filter((c) => c.templateId !== selectedCreativeConfig.templateId)
        .forEach((creativeConfig) => {
          addCreatives.push(new Creative().configureByConfig(creativeConfig));
        })

      addCreatives.forEach((c, ci) => {
        let keepPending = !window.clientConfig.isPro;
        if (c.isSelected) {
          keepPending = false;

          if (!window.clientConfig.features.isAutoStartCreatives && !window.clientConfig.isPro) {
            keepPending = index > startKeepPendingIndex;
          }
        }

        if (keepPending) {
          c.setExtra(Creative.EXTRA_KEEP_PENDING, true);
        }

        c.setAsRefreshed(ci > 0);

        processing.addCreative(c);

        const creativePreviewImageUrl = resolveCreativePreviewFile(c);
        if (creativePreviewImageUrl) {
          (new Image()).src = creativePreviewImageUrl;
        }
      });
    });

    processingManager.update();
  }

  /** @param {Processing} processing */
  handleProcessingChange = (processing) => debounce("ProcessingPage_handleProcessingChange", 100, () => {
    if (window.appConfig.isDebug) {
      console.info("ProcessingPage::handleProcessingChange", JSON.parse(processing.toJSON()));
    }

    const selectedAndStartedCreatives = processing.creatives.filter((c) => {
      return c.isSelected
        && c.getExtra(Creative.EXTRA_KEEP_PENDING, false) !== true
        && !c.hasExtra(Creative.EXTRA_ATTACH_FILE)
        && !c.hasExtra(Creative.EXTRA_DUMMY)
        && c.group !== creativeGroups.COMMON
        && processing.groups.indexOf(c.group) > -1
      ;
    });

    if (selectedAndStartedCreatives.length === 0) {
      this.addCreatives(processing);
      return;
    }

    const processedCreatives = selectedAndStartedCreatives.filter((c) => c.isProcessed);
    const failedCreatives = selectedAndStartedCreatives.filter((c) => c.isFailed);

    if (processedCreatives.length > 0) {
      this.stopProcessingTimer();

      clientStorage.incrementProcessedPhotosAmount();
      if (!clientStorage.hasFirstProcessingProcessedAt()) {
        clientStorage.setFirstProcessingProcessedAt(Date.now());
      }

      const processingStep = processing.getExtra(Processing.EXTRA_STEP, "");
      const elapsedMs = Date.now() - processing.getExtra(Processing.EXTRA_STARTED_AT);

      if (processingStep === Processing.STEP_RESULT) {
        hitEvent(hits.PROCESSING_PROCESSED_PHOTO);
        logProcessingsTimings(elapsedMs, "_photo");

        clientStorage.setProcessingFile(processing.file);
      }
      else if (processingStep === Processing.STEP_RESULT_WITHOUT_FILE) {
        hitEvent(hits.PROCESSING_PROCESSED_NO_PHOTO);
        logProcessingsTimings(elapsedMs, "_nophoto");
      }
      else if (processingStep !== Processing.STEP_LAYOUT) {
        hitEvent(hits.PROCESSING_PROCESSED_ART);
        logProcessingsTimings(elapsedMs, "_art");
      }

      logEvent(userEvents.PROCESSING_PROCESSED, {
        elapsed_time_ms: elapsedMs,
        step: processingStep,
      });

      this.props.history.replace({pathname: routes.RESULT});
    }
    else if (failedCreatives.length === selectedAndStartedCreatives.length) {
      this.stopProcessingTimer();

      const failedCreative = failedCreatives[0];
      const processingStep = processing.getExtra(Processing.EXTRA_STEP, "");
      const elapsedMs = Date.now() - processing.getExtra(Processing.EXTRA_STARTED_AT);

      if (processingStep === Processing.STEP_RESULT) {
        hitEvent(hits.PROCESSING_FAILED_PHOTO);
      } else if (processingStep === Processing.STEP_RESULT_WITHOUT_FILE) {
        hitEvent(hits.PROCESSING_FAILED_NO_PHOTO);
      } else if (processingStep !== Processing.STEP_LAYOUT) {
        hitEvent(hits.PROCESSING_FAILED_ART);
      }

      logEvent(userEvents.PROCESSING_FAILED, {
        elapsed_time_ms: elapsedMs,
        step: processingStep,
      });

      if (failedCreative.error && failedCreative.error.type === "photolab") {
        hitEvent(hits.PROCESSING_FAILED_BY_PHOTOLAB);
        resetNativeAdsCounter();
      }

      this.setState({
        error: failedCreative.error,
        errorWithUploadForm: processing.getExtra(Processing.EXTRA_STEP) !== Processing.STEP_LAYOUT,
      }, this.context.hideLoader);
    }
  });

  handleFileSelected = (file) => {
    const processing = processingManager.processing;
    processing.setExtra(Processing.EXTRA_PROCESSING_FILE, null);
    processingManager.update();

    logEvent(userEvents.PHOTO_SELECT, {page: "processing"});

    this.context.showLoader(true, null, null, () => {
      this.props.history.replace(routes.UPLOAD, {file});
    });
  }

  handleProcessingTimeout = () => {
    processingManager.clear();

    hitEvent(hits.PROCESSING_TIMEOUT);

    this.setState({
      error: {
        type: "processing_timeout",
        code: 1,
        message: "timeout",
      },
      errorWithUploadForm: false,
    }, this.context.hideLoader);
  };

  render() {
    if (this.state.error) {
      return <ErrorView
        error={this.state.error}
        hideUploadForm={!this.state.errorWithUploadForm}
        onFileSelected={this.handleFileSelected}
        onStartOver={() => this.props.history.replace(generatePath(routes.INDEX), {view: "search"})}
      />;
    }

    return <React.Fragment />;
  }
}

ProcessingPage.contextType = AppContext;
