const VERSION = 1;

const KEY_PREFS = "h22:prefs";
const KEY_PREFS_VERSION = "h22:prefs:version";

const KEY_CLIENT_TOKEN = "client_token";
const KEY_SPLIT_GROUP_ID = "split_group_id";
const KEY_WEBVIEW_REQUEST_PARAMS = "webview_request_params";
const KEY_RATEAPP_IS_RATE = "rateapp_request__rate";
const KEY_RATEAPP_IS_SKIP = "rateapp_request__skip";
const KEY_RATEAPP_LAST_SHOW = "rateapp_request__last_show";
const KEY_PRO_WATERMARK_SHOULD_BE_REMOVED = "pro_watermark_should_be_removed";
const KEY_PRO_WATERMARK_SHOW_MODAL_INCREMENT = "pro_watermark_show_modal_increment";
const KEY_FIRST_VISIT_AT = "first_visit_at";
const KEY_FIRST_PROCESSING_PROCESSED_AT = "first_processing_processed_at";
const KEY_FIRST_DOWNLOAD_AT = "first_download_at";
const KEY_COUNT_PROCESSIN_IN_SESSION = "count_processing_in_session";
const KEY_PROCESSED_PHOTOS_AMOUNT = "processed_photos_amount";
const KEY_SELECTED_LANG = "selected_lang";
const KEY_PROCESSING_FILES= "processing_files";
const KEY_ART_SEEDS = "art_seeds_v2";
const KEY_UPLOAD_FILE_TOOLTIP_IS_HIDDEN = "upload_file_tooltip_is_hidden";

class ClientStorage {

  constructor() {
    const prefs = window.localStorage.getItem(KEY_PREFS);
    if (prefs) {
      this.prefs = JSON.parse(prefs);
    } else {
      this.prefs = {};
    }

    const currentVersion = parseInt(window.localStorage.getItem(KEY_PREFS_VERSION) || 1);
    if (currentVersion !== VERSION) {
      this.migrate(currentVersion, VERSION);
      window.localStorage.setItem(KEY_PREFS_VERSION, "" + VERSION);
    }
  }

  migrate(fromVersion, toVersion) {
    // ...
  }

  commit() {
    window.localStorage.setItem(KEY_PREFS, JSON.stringify(this.prefs));
  }

  setBoolean(key, value) {
    this.prefs[key] = !!value;
    this.commit();
  }

  getBoolean(key, defaultValue) {
    if (this.prefs[key] !== undefined) {
      return !!this.prefs[key];
    } else {
      return defaultValue;
    }
  }

  setInteger(key, value) {
    this.prefs[key] = parseInt(value);
    this.commit();
  }

  getInteger(key, defaultValue) {
    if (this.prefs[key] !== undefined) {
      return parseInt(this.prefs[key]);
    } else {
      return defaultValue;
    }
  }

  setString(key, value) {
    this.prefs[key] = "" + value;
    this.commit();
  }

  getString(key, defaultValue) {
    if (this.prefs[key] !== undefined) {
      return "" + this.prefs[key];
    } else {
      return defaultValue;
    }
  }

  // ---

  getClientToken() {
    return this.getString(KEY_CLIENT_TOKEN);
  }

  setClientToken(value) {
    this.setString(KEY_CLIENT_TOKEN, value);
  }

  getFirstVisitAt() {
    return this.getInteger(KEY_FIRST_VISIT_AT);
  }

  setFirstVisitAt(value) {
    this.setInteger(KEY_FIRST_VISIT_AT, value);
  }

  hasFirstVisitAt() {
    return this.getInteger(KEY_FIRST_VISIT_AT, Number.MIN_VALUE) !== Number.MIN_VALUE;
  }

  getFirstProcessingProcessedAt() {
    return this.getInteger(KEY_FIRST_PROCESSING_PROCESSED_AT);
  }

  setFirstProcessingProcessedAt(value) {
    this.setInteger(KEY_FIRST_PROCESSING_PROCESSED_AT, value);
  }

  hasFirstProcessingProcessedAt() {
    return this.getInteger(KEY_FIRST_PROCESSING_PROCESSED_AT, Number.MIN_VALUE) !== Number.MIN_VALUE;
  }

  setWebviewRequestParams(value) {
    if (typeof value !== 'object' || value === null) {
      value = {};
    }

    this.prefs[KEY_WEBVIEW_REQUEST_PARAMS] = value;
    this.commit();
  }

  getWebviewRequestParams() {
    if (this.prefs[KEY_WEBVIEW_REQUEST_PARAMS] === undefined) {
      return {};
    }

    return this.prefs[KEY_WEBVIEW_REQUEST_PARAMS];
  }

  getSplitGroupId() {
    return this.getInteger(KEY_SPLIT_GROUP_ID, undefined);
  }

  setSplitGroupId(value) {
    this.setInteger(KEY_SPLIT_GROUP_ID, value);
  }

  setRateAppRequestIsRate(value) {
    this.setBoolean(KEY_RATEAPP_IS_RATE, value);
  }

  getRateAppRequestIsRate() {
    return this.getBoolean(KEY_RATEAPP_IS_RATE, false);
  }

  setRateAppRequestIsSkip(value) {
    this.setBoolean(KEY_RATEAPP_IS_SKIP, value);
  }

  getRateAppRequestIsSkip() {
    return this.getBoolean(KEY_RATEAPP_IS_SKIP, false);
  }

  setRateAppRequestLastShow(value) {
    this.setInteger(KEY_RATEAPP_LAST_SHOW, value);
  }

  getRateAppRequestLastShow() {
    return this.getInteger(KEY_RATEAPP_LAST_SHOW, 0);
  }

  setUploadFileTooltipIsHidden(value) {
    this.setBoolean(KEY_UPLOAD_FILE_TOOLTIP_IS_HIDDEN, value);
  }

  getUploadFileTooltipIsHidden() {
    return this.getBoolean(KEY_UPLOAD_FILE_TOOLTIP_IS_HIDDEN, false);
  }

  getProWatermarkShouldBeRemoved() {
    return this.getBoolean(KEY_PRO_WATERMARK_SHOULD_BE_REMOVED, false);
  }

  setProWatermarkShouldBeRemoved(value) {
    this.setBoolean(KEY_PRO_WATERMARK_SHOULD_BE_REMOVED, value);
  }

  getProWatermarkShowModal() {
    return this.getInteger(KEY_PRO_WATERMARK_SHOW_MODAL_INCREMENT, 0);
  }

  incrementProWatermarkShowModal() {
    const value = this.getProWatermarkShowModal() + 1;

    this.setInteger(KEY_PRO_WATERMARK_SHOW_MODAL_INCREMENT, value);
  }

  getFirstDownloadAt() {
    return this.getInteger(KEY_FIRST_DOWNLOAD_AT);
  }

  setFirstDownloadAt(value) {
    this.setInteger(KEY_FIRST_DOWNLOAD_AT, value);
  }

  setCountProcessingInSession(sessionId, count) {
    this.setString(KEY_COUNT_PROCESSIN_IN_SESSION, `${sessionId}:${count}`);
  }

  getCountProcessingInSession() {
    return this.getString(KEY_COUNT_PROCESSIN_IN_SESSION, "-1:0")
      .split(":")
      .map((item) => parseInt(item));
  }

  getProcessedPhotosAmount() {
    return this.getInteger(KEY_PROCESSED_PHOTOS_AMOUNT, 0);
  }

  incrementProcessedPhotosAmount() {
    this.setInteger(
      KEY_PROCESSED_PHOTOS_AMOUNT,
      this.getInteger(KEY_PROCESSED_PHOTOS_AMOUNT, 0) + 1
    );
  }

  getSelectedLang() {
    return this.getString(KEY_SELECTED_LANG, null);
  }

  setSelectedLang(value) {
    this.setString(KEY_SELECTED_LANG, value);
  }

  removeProcessingFile(file) {
    const processingFiles = this.prefs[KEY_PROCESSING_FILES] || [];

    const index = processingFiles.findIndex((f) => f.hash === file.hash);

    if (index !== -1) {
      processingFiles.splice(index, 1);
    }

    this.prefs[KEY_PROCESSING_FILES] = processingFiles;
    this.commit();
  }

  setProcessingFile(file) {
    const processingFiles = this.prefs[KEY_PROCESSING_FILES] || [];
    const storedFile = processingFiles.find((f) => f.hash === file.hash);

    if (storedFile && storedFile.url !== file.url) {
      storedFile.url = file.url;
    }

    const index = processingFiles.findIndex((f) => f.url === file.url);

    if (index === -1) {
      processingFiles.push({url: file.url, hash: file.hash, createdAt: Date.now()});
    }

    this.prefs[KEY_PROCESSING_FILES] = processingFiles;
    this.commit();
  }

  getProcessingFiles(limit = 5) {
    if (this.prefs[KEY_PROCESSING_FILES] === undefined) {
      return [];
    }

    const now = Date.now();

    const processingFiles = this.prefs[KEY_PROCESSING_FILES]
      .filter((f) => now < (f.createdAt + 86_400_000 * 3))
      .sort((f1, f2) => f2.createdAt - f1.createdAt)
      .slice(0, limit);

    this.prefs[KEY_PROCESSING_FILES] = processingFiles;
    this.commit();

    return processingFiles;
  }

  getArtSeeds(seedsCount, min, max) {
    let seeds;
    try {
      const v = JSON.parse(this.getString(KEY_ART_SEEDS, "[]"));
      if (Array.isArray(v)) {
        seeds = v;
      } else {
        seeds = [];
      }
    } catch (e) {
      seeds = [];
    }

    if (seeds.length < seedsCount) {
      for (let i = seeds.length; i < seedsCount; i++) {
        seeds[i] = parseInt(Math.random() * (max - min) + min);
      }

      this.setString(KEY_ART_SEEDS, JSON.stringify(seeds));
    }

    return seeds;
  }
}

export default new ClientStorage();
