import axios from "axios";
import {debounce} from "./etc";

let webviewParamsIsCommited = false;

const userProperties = {
  client_type: window.clientConfig.isWebview ? "webview" : (window.clientConfig.isWebMobile ? "mobile" : "desktop"),
  is_webview: window.clientConfig.isWebview,
  is_mobile: window.clientConfig.isWebMobile,
  is_mobile_desktop_mode: window.clientConfig.isWebMobileDesktopMode,
  platform_browser_name: window.clientConfig.platform.name,
  platform_browser_version: window.clientConfig.platform.version,
  platform_os: window.clientConfig.platform.os,
  screen_w: window.screen.width,
  screen_h: window.screen.height,
  viewport_w: Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
  viewport_h: Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0),
  locale: window.clientConfig.locale,
  is_pro: window.clientConfig.isPro,
  split_group: window.clientConfig.splitGroupId,
};

if (window.clientConfig.isWebview) {
  const osName = window.clientConfig.isWebviewAndroid
    ? "Android"
    : "iOS";

  userProperties.os_version = `${osName} ${window.clientConfig.webviewParams.os_version}`;
  userProperties.native_app_build = `${osName} ${window.clientConfig.webviewParams.version_code}`;
}

if (window.clientConfig.loadedUrlParams["ref"]) {
  userProperties.ref = window.clientConfig.loadedUrlParams["ref"];
}

if (window.clientConfig.loadedUrlParams["utm_source"]) {
  userProperties.utm_source = window.clientConfig.loadedUrlParams["utm_source"];
}

const userPropertiesCommitWaited = Object.keys(userProperties);

if (window.appConfig.isDebug) {
  console.log("initial user properties", userProperties);
}

// --

export const userEvents = {
  PAGE: "page",

  // юзер нажал на "попробовать другое фото"
  // параметры: page (страница: create, result, error)
  HOME_BUTTON_CLICK: "home_button_click",

  BACK_BUTTON_CLICK: "back_button_click",

  // юзер выбрал фото
  // параметры: page (страница: index, error, result)
  PHOTO_SELECT: "photo_select",

  // юзер успешно загрузил фото
  PHOTO_UPLOADED: "photo_uploaded",

  // юзер неуспешно загрузил фото
  PHOTO_UPLOAD_FAILED: "photo_upload_failed",

  TAB_SELECT: "tab_select",
  TAB_REFRESH: "tab_refresh",

  PROCESSING_PROCESSED: "processing_processed",
  PROCESSING_FAILED: "processing_failed",

  CREATIVE_STARTED: "creative_started",
  CREATIVE_PROCESSED: "creative_processed",
  CREATIVE_FAILED: "creative_failed",

  CREATIVE_VIEW: "creative_view",

  DOWNLOAD: "download",

  GET_APP_CLICK: "get_app_click",

  RATEAPP_DIALOG_SHOW: "rateapp_dialog_show",
  RATEAPP_DIALOG_RATE: "rateapp_dialog_rate",
  RATEAPP_DIALOG_SKIP: "rateapp_dialog_skip",

  COMPONENT_ERROR: "component_error",
  JS_GLOBAL_ERROR: "js_global_error",

  FRONTEND_WATERMARK_FAILED: "frontend_watermark_failed",

  LCP_REPORT: "lcp_report",

  OPEN_TERM_FORM: "open_term_form",
  CLOSE_TERM_FORM: "close_term_form",
  SUBMIT_TERM_FORM: "submit_term_form",

  PROCEED_BUTTON_CLICK: "proceed_button_click",
  SKIP_BUTTON_CLICK: "skip_button_click",
};

export const hits = {
  TAB_OPENED: 0,
  PHOTO_SELECT: 0,
  PHOTO_UPLOADED: 0,

  PROCESSING_START_ART: 9269,
  PROCESSING_START_PHOTO: 9270,
  PROCESSING_START_NO_PHOTO: 9271,
  PROCESSING_PROCESSED_ART: 9263,
  PROCESSING_PROCESSED_PHOTO: 9264,
  PROCESSING_PROCESSED_NO_PHOTO: 9265,
  PROCESSING_FAILED_ART: 9266,
  PROCESSING_FAILED_PHOTO: 9267,
  PROCESSING_FAILED_NO_PHOTO: 9268,
  PROCESSING_FAILED_BY_PHOTOLAB: 0,
  PROCESSING_FAILED_ON_RESULT_PAGE: 0,
  PROCESSING_TIMEOUT: 0,

  DOWNLOAD: 0,
  FIRST_DOWNLOAD_BY_CLIENT: 0,
  FIRST_DOWNLOAD_BY_PROCESSING: 0,
  FIRST_DOWNLOAD_BY_TEMPLATE: 0,

  GET_APP_CLICK: 0,
  RATEAPP_DIALOG_SHOW: 0,
  RATEAPP_DIALOG_RATE: 0,
  RATEAPP_DIALOG_SKIP: 0,
  RATEAPP_DIALOG_INAPP_RESPONSE_0: 0,
  RATEAPP_DIALOG_INAPP_RESPONSE_1: 0,
  RATEAPP_DIALOG_INAPP_RESPONSE_2: 0,
  RATEAPP_DIALOG_INAPP_RESPONSE_3: 0,
  INDEX_VISIT_WEB_DESKTOP: 0,
  INDEX_VISIT_WEB_MOBILE: 0,
  INDEX_VISIT_WEBVIEW: 0,
  NATIVE_ADS_PRELOAD: 0,
  NATIVE_ADS_SHOW_REQUEST: 0,
  NATIVE_ADS_SHOWN: 0,
  START_PENDING_CREATIVE: 0,
  FIRST_CLICK_TAB: 0,
  FIRST_CLICK_REFRESH_CREATIVE: 0,

  FRONTEND_WATERMARK_STARTED: 0,
  FRONTEND_WATERMARK_PROCESSED: 0,
  FRONTEND_WATERMARK_FAILED: 0,
  FRONTEND_WATERMARK_FAILED_TIMEOUT: 0,
  COMPONENT_ERROR: 0,
  START_PENDING_PROCESSING: 0,
  START_AUTO_PROCESSING: 0,
  DOWNLOAD__AUTO_PROCESSING: 0,
  ANALYTICS_ERROR: 0,

  FORCE_REDIRECT_PROCESSING: 0,

  REQUEST_PHOTOLAB_SIGN_FAIL: 0,
  REQUEST_PHOTOLAB_SIGN_ALT_FAIL: 0,

  DOWNLOAD_WITH_WATERMARK: 0,
  DOWNLOAD_WITHOUT_WATERMARK: 0,

  WATERMARK_POPUP_REMOVE_CLICK: 0,
  WATERMARK_POPUP_KEEP_CLICK: 0,
};

const hitsCache = {};
export function hitEvent(id, count = 1, ignoreUserGroup = false, delay = 1000) {
  const config = window.appConfig.hits;

  if (id === 0) {
    console.warn("Zero-valued hit triggered");
    return;
  }

  if (window.appConfig.isDebug) {
    const hitName = Object.keys(hits).find((key) => hits[key] === id) || "(unknown)";
    console.info("hitEvent", JSON.stringify({hitName, id, count, ignoreUserGroup}));
  }

  if (!config.isEnabled) {
    return;
  }

  if (ignoreUserGroup || config.allowedUserGroups.includes(window.clientConfig.splitGroupId)) {
    hitsCache[id] = (hitsCache[id] || 0) + count;
    debounce("hitEvent." + id, delay, () => {
      const c = hitsCache[id] || 1;
      hitsCache[id] = 0;

      window.axios.post(`${config.endpoint}?id=${id}&c=${c}&r=${Date.now()}`)
        .then(() => {/* dummy */})
        .catch(console.error);
    });
  }
}

export function logEvent(eventId, eventParams, cb) {
  eventParams = eventParams || {};
  eventParams.build_version = window.appConfig.build.version;

  if (window.appConfig.isDebug) {
    console.debug("logEvent", eventId, JSON.stringify(eventParams));
  }

  if (window.appConfig.analytics.isEnabled) {
    const isAllow = !window.appConfig.analytics.overloadModeIsEnabled
      ||
      (window.appConfig.analytics.overloadModeIsEnabled
        && window.appConfig.analytics.overloadModeEvents.includes(eventId))
    ;

    if (isAllow) {
      _logEvent(eventId, eventParams).then(() => {
        cb && cb();
      });
    }
  }
}

function _logEvent(eventId, eventParams) {
  let userParams = undefined;
  if (userPropertiesCommitWaited.length > 0) {
    userParams = {};
    userPropertiesCommitWaited.forEach((key) => userParams[key] = userProperties[key]);
    userPropertiesCommitWaited.length = 0;
  }

  let webviewParams = undefined;
  if (window.clientConfig.isWebview && !webviewParamsIsCommited) {
    webviewParamsIsCommited = true;
    webviewParams = window.clientConfig.webviewParams;
  }

  return axios.post(window.appConfig.analytics.endpoint, {
    client_token: window.clientConfig.token,
    client_params: userParams,
    client_webview_params: webviewParams,
    project_name: window.appConfig.project.name,
    event_name: eventId,
    event_params: eventParams,
    locale: window.clientConfig.locale,
  }).then(() => {
    /* skip */
  }).catch((err) => {
    hitEvent(hits.ANALYTICS_ERROR, 1, true, 1);
    console.error(err);
  });
}

export function setUserProperty(key, value) {
  if (window.appConfig.isDebug) {
    console.debug("setUserProperty", key, value);
  }

  const currentValue = userProperties[key];
  if (currentValue !== value) {
    userProperties[key] = value;
    userPropertiesCommitWaited.push(key);
  }
}


export function logProcessingsTimings(time, suffix = "") {
  if (!window.appConfig.processingTimings.isEnabled) {
    return;
  }

  axios.post(window.appConfig.processingTimings.endpoint, {
    project_name: window.appConfig.project.name + suffix,
    time,
  }).then(() => {
    /* skip */
  }).catch((err) => {
    console.error(err);
  });
}
