const textDrawDebug = window
  && window.localStorage
  && window.localStorage["vicman_debug_texts"] === "1";

class TextSpan {
  static TYPE_NEWLINE = 0;
  static TYPE_TEXT = 1;
  static TYPE_SPACE = 2;
  static TYPE_NON_BREAKING_SPACE = 3;

  type = TextSpan.TYPE_TEXT;
  text = "";
  textColor = 0x0;
  textWeight = 400;
  textStyle = "normal";
  fontSize = 16;
  fontName = "serif";
  width = 0;
  height = 0;
}

class TextLine {
  width = 0;
  height = 0;
  spans = [];
  textAlign = "left";
}

export function textToSpans(text) {
  const spans = [
    new TextSpan(),
  ];

  for (let i = 0; i < text.length; i++) {
    let char = text[i];

    if (char === "\n") {
      const span = new TextSpan();
      span.type = TextSpan.TYPE_NEWLINE;
      spans.push(span);
      spans.push(new TextSpan());
    } else if (char === " ") {
      spans.last().text += char;
    } else if (/\s/.test(char)) {
      spans.push(new TextSpan());
    } else {
      spans.last().text += char;
    }
  }

  return spans;
}

export function spanTextToStyleString(span) {
  return `${span.textStyle} ${span.textWeight} ${span.fontSize}px '${span.fontName}'`;
}

export function textSpansToLines(canvas, spans, optionsArg = {}) {
  const ctx = canvas.getContext("2d");
  const options = Object.assign({
    width: 0,
    height: 0,
    fontSize: 16,
    lineHeight: 1,
  }, optionsArg);

  const lines = [];
  let spaceWidth = 0;
  let oLetterWidth = 0;
  let totalWidth = 0;
  let totalHeight = 0;

  for (let fontSize = options.fontSize; fontSize > 1; fontSize--) {
    spaceWidth = 0;
    oLetterWidth = 0;

    lines.splice(0, lines.length);
    lines.push(new TextLine());

    for (let i = 0; i < spans.length; i++) {
      const span = spans[i];
      span.fontSize = fontSize;

      ctx.font = spanTextToStyleString(span);

      if (spaceWidth === 0) {
        spaceWidth = ctx.measureText(" ").width;
      }

      if (oLetterWidth === 0) {
        oLetterWidth = ctx.measureText("O").width;
      }

      if (span.type === TextSpan.TYPE_NEWLINE) {
        lines.push(new TextLine());
        continue;
      }

      span.width = ctx.measureText(span.text).width;
      span.height = oLetterWidth;

      if (options.width > 0 && lines.last().width + spaceWidth + span.width > options.width) {
        lines.push(new TextLine());
      }

      lines.last().spans.push(span);
      lines.last().width += (lines.last().spans.length > 1 ? spaceWidth : 0) + span.width;
      lines.last().height = oLetterWidth * options.lineHeight;
    }

    totalWidth = lines.max((line) => line.width);
    totalHeight = lines.reduce((r, line) => r + line.height, 0);

    if (totalWidth <= options.width && totalHeight <= options.height) {
      break;
    }
  }

  return {
    lines,
    spaceWidth,
    oLetterWidth,
    totalWidth,
    totalHeight,
  };
}

export function drawTextLines(ctx, measuredLinesData, bounds, optionsArg = {}) {
  const options = Object.assign({
    verticalAlign: "top",
  }, optionsArg);

  let y = bounds.y;

  if (options.verticalAlign === "middle") {
    y += (bounds.height - measuredLinesData.totalHeight) / 2;
  } else if (options.verticalAlign === "bottom") {
    y += bounds.height - measuredLinesData.totalHeight;
  }

  measuredLinesData.lines.forEach((line) => {
    let x = bounds.x;

    if (line.textAlign === "center") {
      x += parseInt((bounds.width - line.width) / 2);
    } else if (line.textAlign === "right") {
      x += parseInt(bounds.width - line.width);
    }

    drawDebugRect(ctx, {
      x,
      y,
      width: line.width,
      height: line.height,
    }, 5, "blue");

    line.spans.forEach((span) => {
      ctx.save();
      ctx.font = spanTextToStyleString(span);
      ctx.fillStyle = span.textColor;
      ctx.textBaseline = "top";
      ctx.textAlign = "start";
      ctx.fillText(span.text, x, y + (line.height - span.height)/2);
      ctx.restore();

      x += span.width + measuredLinesData.spaceWidth;
    });

    y += line.height;
  });

  return {
    y
    // min x
    // min y
    // max x
    // max y
  }
}

export function drawDebugRect(ctx, bounds, strokeWidth, color) {
  if (textDrawDebug) {
    ctx.save();
    ctx.strokeStyle = color;
    ctx.strokeWidth = strokeWidth;
    ctx.strokeRect(bounds.x, bounds.y, bounds.width, bounds.height);
    ctx.restore();
  }
}